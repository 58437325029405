/* eslint-disable no-console */
import isEmpty from 'lodash/isEmpty';
import Cookies from 'universal-cookie';
import { authHeaderKeys, proxyAuthHeaderKeys } from './constants';

const isTestingMode = process.env.NODE_ENV === 'testing';
const defaultStorage = isTestingMode ? new Cookies() : window.sessionStorage; // Default session storage

const defaultPathForCookieStorage = '/';

export const getAuthHeaders = () => {
  const headers = {};
  authHeaderKeys.forEach((key) => {
    headers[key] = getItemFromStorage(key);
  });
  return headers;
};

export const persistAuthHeadersInDeviceStorage = (
  headers,
  accessToken = null
) => {
  authHeaderKeys.forEach(key => {
    if (key === 'authorization') {
      if (accessToken) {
        setItemToStorage('token', accessToken);
      } else {
        const token = headers[key].split(' ')[1];
        setItemToStorage('token', token);
      }
    } else {
      setItemToStorage(key, headers[key]);
    }
  });
};

export const persistProxyAuthHeadersInDeviceStorage = (headers) => {
  proxyAuthHeaderKeys.forEach((key) => {
    setItemToStorage(key, headers[key]);
  });
};

export const deleteAuthHeadersFromDeviceStorage = () => {
  authHeaderKeys.forEach((key) => {
    removeItemFromStorage(key);
  });
};

export const persistTokenAuthHeaderInDeviceStorage = accessToken => {
  setItemToStorage('token', accessToken);
};




const deleteAllCookies = (storage = defaultStorage) => {
  const result = storage.getAll();
  Object.keys(result).forEach((key) => {
    removeItemFromStorage(key, storage);
  });
};

export const setItemToStorage = (key, value, storage = defaultStorage) => {
  if (isTestingMode) {
    if (isEmpty(value)) {
      return storage.set(key, value, { path: defaultPathForCookieStorage });
    }
    return storage.set(key, JSON.stringify(value), { path: defaultPathForCookieStorage });
  }
  return storage.setItem(key, value);
};

export const getItemFromStorage = (key, storage = defaultStorage) => isTestingMode ? storage.get(key) : storage.getItem(key);

export const getDefaultStorage = () => defaultStorage;


export const clearDefaultStorage = () => isTestingMode ? deleteAllCookies() : defaultStorage.clear();

export const removeItemFromStorage = (key, storage = defaultStorage) => isTestingMode ? storage.remove(key) : storage.removeItem(key);

export const getCurrentUser = () => {
  if (!isLoggedIn()) {
    return null;
  }
  try {
    const user = getItemFromStorage('currentUser');
    return user ? JSON.parse(user) : null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const isLoggedIn = () => !isEmpty(getItemFromStorage('token'));

export const getActiveCompositeProcedureId = () => {
  const compositeProcedureId = getItemFromStorage('activeCompositeProcedureId');
  try {
    if (compositeProcedureId !== null && !Number.isNaN(compositeProcedureId)) {
      return parseInt(compositeProcedureId, 10);
    }
  } catch (error) {
    console.log('getActiveCompositeProcedureId', error);
  }
  return 0;
};

export const isWEbContainerRequest = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const platform = searchParams.get("platform") || searchParams.get("Platform");
  return platform?.toLowerCase() === 'android' || platform?.toLowerCase() === 'ios'
}


export const getPlatform = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const platform = searchParams.get("platform") || searchParams.get("Platform");
  return (['android', 'ios'].includes(platform?.toLowerCase()) ? platform : "web")
}